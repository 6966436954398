.about-as-page{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 7em;
    @media(max-width: 1200px){
        font-size: calc(0.25vw + 7px);
    }
    @media(max-width: 800px){
        font-size: calc(0.25vw + 9px);
    }
    .link{
        p{
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 1.6em;
        }
        a{
            text-decoration: none;
            color: #9d74a0; 
        }
        span{
            color: #7e7e7e;
        }
    }
    .caption{
        margin-bottom: 2.5em;
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 4.6em;
            text-align: center;
        }
        @media(max-width: 600px){
            margin-bottom: 1.5em;
            p{
                font-size: 3em;
            }
        }
    }
    .separator{
        width: 1px;
        height: 4em;
        background-color: #9d74a0;
        margin: 0 auto 2.5em;
    }
    img{
        width: 100%;
    }
    p{
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 1.6em;
        font-weight: 300;
        letter-spacing: 0.16px;
    }
    .about-as-wrap{
        max-width: 770px;
        margin: 0 auto;
    }
    .more-about-as{
        margin: 3.5em 0;
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-size: 3em;
            font-weight: 300;
            letter-spacing: 0.75px;
        }
    }
    .about-as-list{
        p{
            padding-left: 2em;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                top: 5px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #865b87;
            }
        }
    }
    .about-as-center{
        text-align: center;
    }
    .about-as-imag{
        margin: 3.5em 0;
    }
}
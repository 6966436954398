.busket-container {
    position: fixed;
    max-width: 330px;
    width: 100%;
    background: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    padding:47px 30px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.17);
    transform: translateX(calc(100% + 10px));
    transition: .3s;
    z-index: 2;
    @media(max-width: 460px){
        max-width: unset;
    }
    &.active{
        transform: translateX(0);
    }
    .title {
        color: #2a3137;
font-family: "FuturaPT";
font-size: 18px;
font-weight: 300;
line-height: 20px;
text-transform: uppercase;
letter-spacing: 0.45px;
margin-bottom: 39px;
    }

    .close-triggrer {
        button{
            background: transparent;
            border: none;
            width: 17px;
height: 17px;
opacity: 0.8;
position: absolute;
padding: 0;
right: 30px;
top: 50px;
&::before, &::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
height: 2px;
background-color: #2a3137;
}
&::after{
transform: rotate(45deg);

}
&::before{
transform: rotate(-45deg);
}

        }
    }

    .busket-item-wr {
        margin-bottom: 45px;
    }

    .busket-item {
        padding: 24px 0;
        display: flex;
        border-bottom: 1px solid #eee;
        .pics {
            padding: 5px;
            background-color: #f5f5f5;
            margin-right: 29px;
            img {}
        }

        .caption {
            .name {
                color: #2a3137;
font-family: "PoiretOne";
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.45px;
margin-bottom: 8px;
            }

            .quantity {
font-family: "FuturaPT";
font-size: 18px;
font-weight: 400;
line-height: 20px;
text-transform: uppercase;
color: #7e7e7e;
margin-bottom: 4px;
.summ{
    color: #9d74a0;
}
            }

            .delete-trigger {
                button {
color: #7e7e7e;
font-family: "FuturaPT";
font-size: 14px;
font-weight: 300;
line-height: 20px;
text-decoration: underline;
letter-spacing: 0.35px;
background: transparent;
border: none;
                }
            }
        }
    }

    .price {
        display: flex;
        justify-content: space-between;
        .price-text {
            color: #313131;
font-family: "FuturaPT";
font-size: 18px;
font-weight: 400;
line-height: 20px;
text-transform: uppercase;
letter-spacing: 0.45px;
margin-bottom: 30px;
        }

        .price-value {
color: #9d74a0;
font-family: "FuturaPT";
font-size: 20px;
font-weight: 400;
line-height: 20px;
text-transform: uppercase;
        }
    }

    .delivery {
        display: flex;
        justify-content: space-between;
        margin-bottom: 45px;
        .delivery-text {
color: #313131;
font-family: "FuturaPT";
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.45px;
        }

        .delivery-value {
            color: #313131;
font-family: "FuturaPT";
font-size: 20px;
font-weight: 400;
line-height: 20px;

        }
    }

    .checkout {
        margin-bottom: 20px;
        button {}
    }

    .back-busket {
        button {}
    }
}

.pc-busked{
    .icon{
        img{
          max-width: 70px;  
        }
    }
}
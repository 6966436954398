
.catalog-filter-title {
    color: #313131;
    font-family: "PoiretOne";
    font-size: 36px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.36px;
    margin-bottom: 35px;
    @media(max-width: 567px){
        font-size: 30px; 
    }
    &.search-result {
        p {
            margin-top: 15px;
            color: #6d6d6d;
            font-family: "FuturaPT";
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.14px;
        }
    }
}
 .breadcrumb-wr {
        padding-top: 7em;
    }
.catalog-filter {
   
    img{
        width: 100%;
    }
}

.show {


    .show-container {
        display: flex;
        align-items: center;
    }

    .show-label {
        @media(max-width: 600px){
            display: none;
        }
    }

    .show-dropdown {
        min-width: 65px;
    }
}

.sort-wr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sort-label,
.show-label {
    color: #6d6d6d;
    font-family: "FuturaPT";
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    margin-right: 15px;

}
.sort-label{
    @media(max-width: 600px){
        display: none;
    }
}
.sort {
    display: flex;

    .sort-container {
        display: flex;
        align-items: center;



    }
}

.sort-dropdown {
    min-width: 175px;
}

.catalog-filter-content {
    font-size: calc(0.25vw + 5px);
    border-left: 1px solid #eee;
    margin-bottom: 8em;

    .filter-result-txt {
        color: #6d6d6d;
        font-family: "FuturaPT";
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.35px;
        margin-bottom: 20px;
    }

    .selected-filters-wr {
        display: flex;
        margin-bottom: 2px;
        width: 100%;
        flex-wrap: wrap;
    }
    .filter-button{
        cursor: pointer;
        display: none;
        @media(max-width: 600px){
            display: block;
        }
        span{
            color: #97729b;
            font-family: "FuturaPT";
            font-size: 14px;
            font-weight: 300; 
        }
        i{
            margin-left: 5px;
            color: #97729b;
            font-size: 14px;
        }
    }
    .selected-filters-item {
        display: flex;
        align-items: center;
        padding: 3px 10px 5px;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;


        border-radius: 3px;
        background-color: #97729b;

        &.delete-all {
            background: transparent;
            border: 1px solid #97729b;

            span {
                color: #97729b;
            }

            .delete-filter {

                &::before,
                &::after {
                    background: #97729b;
                }
            }
        }

        span {
            color: #ffffff;
            font-family: "FuturaPT";
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: 0.4px;
        }

        .delete-filter {
            width: 10px;
            height: 10px;
            position: relative;
            margin-left: 5px;
            margin-top: 2px;

            &::before,
            &::after {
                content: "";
                width: 100%;
                height: 2px;
                background: #fff;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                transform-origin: center;
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }

    .pagination-wr {
        display: flex;
        justify-content: center;
        padding-bottom: 6em;
        
    }

    .category-wr {
        display: flex;
        align-items: center;
        margin-bottom: 6.5em;
        @media(max-width: 567px){
            flex-direction: column;
            align-items: flex-start;
        }
        .category-item {
            width: 33.3333%;
            display: flex;
            align-items: center;
            @media(max-width: 567px){
                width: 100%;
                font-size: 1.3em;
    padding: 15px 0;
    margin-top: -1px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
            }
            &:hover {
                text-decoration: none;
            }

            img {
                margin-right: 1.8em;
            }

            span {
                color: #97729b;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
                line-height: 1.55;
                letter-spacing: 0.045em;
            }
        }
    }

    .ceo-block {
        border-top: 1px solid #eeeeee;
        margin-top: 8em;

        @media(max-width: 567px) {
            font-size: 1.4em;
        }

        p {
            color: #b6b6b6;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 300;
        }

        .ceo-caption {
            h3 {
                color: #7e7e7e;
                font-size: 3.6em;
                font-family: "FuturaPT";
                font-weight: 300;
            }
        }
    }
}

.filter-result-items {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8em;

    .product-wr {
        width: 33.3333%;
        height: 38em;
        @media(max-width: 700px){
            width: 50%;
            height: auto;
        }
        &:hover {
            z-index: 2;
        }
    }
}
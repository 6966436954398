.first-screen {
    position: relative;
    min-height: 43em;

    background-size: cover;
    /* background-position: center;
     */
    background-position-x: 50%;
    background-position-y: 31%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 100%);

    }

    h1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 200;
        padding: 0 15px;
        font-family: "PoiretOne";
        font-size: 60px;

        @media(max-width: 567px) {
            font-size: 32px;
        }
    }



}

header {
    .lang-drop {
        position: absolute;
        top: calc(100% - 6px);
    left: -1px;
    right: -1px;
    width: calc(100% + 2px);
    border-top: none;
    opacity: 0;
    pointer-events: none;

        .drop-inner {
            padding: 5px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    border-top: none;
        }

        .lang-item {
            a {
                font-family: FuturaPT;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: .45px;
                font-size: 18px;
    line-height: 24px;
    color: #9d74a0;
    @media(max-width: 767px){
        font-size: 14px;
    line-height: 19px;
    }
            }
        }
    }

    .container {
        @media (max-width: 767px) {
            width: 100%;
        }

    }

    &.scrolled {
        background: #fff;
        padding-top: 0;
        padding-bottom: 0;
        .right-menu .currency{
            color: #000;
            span{
                color: #000;
            }
        }
        @media(max-width: 767px) {
            padding-top: 8px;
        }

        &.open-catalog,
        &.dark-header {
            background: #fff;

        }

        .catalog {
            top: 97px;
            @media(max-width: 767px){
                top: 94px;
            }
            @media(max-width: 460px){
                top: 82px;
            }

        }
        .checkout{
            button{
               color: #fff; 
            }
            
        }
        .back-busket{
            button{
                color: #9d74a0;
            }
        }
        .scrolled-line {
            opacity: 1;
            pointer-events: auto;
            height: auto;

            @media(max-width: 767px) {
                margin-top: 8px;
            }
        }

        .no-scroll-content {
            opacity: 0;
        }

        .scroll-content {
            opacity: 1;
        }

        svg {
            path {
                fill: #000;
            }

            circle {
                fill: #9d74a0;
            }
        }

        button {
            color: #2a3137;
            font-weight: 400;

            span {
                color: #2a3137;
                font-weight: 400;
            }
        }

        .lang {
            button {
                color: #2a3137;
                font-weight: 400;

                span {
                    color: #2a3137;
                    font-weight: 400;
                }
            }
        }

    }
    
    .scroll-content {
        position: absolute;
        opacity: 0;
        top: 1px;
        left: 6px;
    }

    button,
    .logo-lnk {
        position: relative;
    }

    .logo-lnk {
        display: flex;
        max-width: 105px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;

        .scroll-content {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.left-menu {
    display: flex;
    align-items: center;


    @media(max-width: 767px) {
        justify-content: space-between;
        width: 100%;
        max-width: 105px;
    }

    &>button {
        background: transparent;
        border: none;

    }

    .catalog-trigger {
        margin-right: 20px;

        @media(max-width: 767px) {
            margin-right: 0;
            display: flex;
            margin-left: -5px;

            button {
                svg {
                    display: block;
                    width: 19px;
                    height: auto;
                }
            }
        }
    }

    .lang {
        color: #ffffff;
        margin-right: 20px;
        position: relative;
        
        border: 1px solid transparent;
        &.opened{
            background: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    span{
        color: #9d74a0;
        font-weight: 300;
    }
    .fa {
        color: #9d74a0;
    }
    .lang-drop{
        opacity: 1;
        pointer-events: auto;
    }
        }
        @media(max-width: 767px) {
            margin-right: 0;
            display: flex;

            button {
                outline: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 52px;

                span {
                    transition: .3s;
                    font-size: 14px;
                    line-height: 19px;
                }

                .fa {
                    transition: .3s;
                    font-size: 14px;
                    line-height: .6;
                }
            }
        }

        .fa {
            font-size: 18px;
            line-height: 24px;
        }


        span {
            font-family: "FuturaPT";
            font-size: 18px;
            font-weight: 200;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.45px;
        }
    }

    .search {
        font-size: 20px;
        color: #fff;
        display: flex;
        position: relative;

        @media(max-width: 767px) {
            .search-btn {
                display: flex;

                svg {
                    height: 18px;
                    width: auto;
                }
            }
        }

        .search-container {
            position: absolute;
            box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.17);
            border-radius: 5px;
            overflow: hidden;
            left: -10px;
            top: -15px;
            background: #fff;
            opacity: 0;
            transition: .3s;
            pointer-events: none;
            width: 346px;
            z-index: 2;

            @media(max-width: 767px) {
                left: 15px;
                right: 15px;
                top: 61px;
                position: fixed;
                width: auto;
                max-width: 100%;
            }

            &.active {
                opacity: 1;
                pointer-events: auto;
            }

            .input-part {

                input {
                    padding: 15px 16px 11px 42px;
                    border: none;
                    width: 100%;
                    border-bottom: 1px solid rgba(115, 115, 115, .3);
                    color: #7e7e7e;
                    font-family: "FuturaPT";
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.45px;
                }

                img {
                    cursor: pointer;
                    position: absolute;
                    top: 16px;
                    display: block;
                    left: 16px;
                        width: auto;
                }
            }

            .result-part {
                .result-item {
                    &:nth-child(even) {
                        background-color: #faf4fa;
                    }

                    padding: 12px 16px 13px 42px;
                    color: #7e7e7e;
                    font-family: "FuturaPT";
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.45px;
                }
            }
        }

        img {
            display: block;
        }
    }
}

.right-menu {
    display: flex;
    align-items: center;

    @media(max-width: 767px) {
        max-width: 105px;
        margin-left: auto;
    }

    .currency-item {
        margin-right: .5em;

        &:last-child {
            margin-right: 0;
            margin-left: .5em;
        }
    }

    .currency {
        font-family: "FuturaPT";
        font-size: 18px;
        font-weight: 200;
        line-height: 24px;
        color: #ffffff;
        display: flex;

        @media(max-width: 993px) {
            display: none;
        }

        input {
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;
            opacity: 0;

            &:checked {
                &+span {
                    color: #cba674;
                    letter-spacing: 0.45px;
                    text-decoration: underline;
                }


            }

        }

        span {
            font-family: "FuturaPT";
            font-size: 18px;
            font-weight: 200;
            line-height: 24px;
            color: #ffffff;
            transition: .3s;

            text-transform: uppercase;
            letter-spacing: 0.45px;

        }

    }

    .account-wr {
        display: flex;
        margin-left: auto;
        position: relative;

        @media(max-width: 767px) {
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .account {
                display: flex;
                margin: 0;
                margin-right: -5px;

                svg {
                    display: block;
                    height: 18px;
                    width: auto;

                }
            }
        }
    }

    .favorite {
        .fa {
            font-size: 20px;
            color: #fff;
        }
    }
}

.menu {
    background: transparent;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding-top: 17px;

    padding-bottom: 17px;

    &.open-catalog,
    &.dark-header {
        background-color: #2b122c;
        background-image: linear-gradient(to top, rgba(83, 91, 94, 0.19) 0%, rgba(254, 254, 254, 0.19) 10%, rgba(78, 82, 84, 0.19) 51%, rgba(253, 253, 253, 0.19) 84%, rgba(83, 91, 94, 0.19) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.68) 0%, rgba(140, 96, 144, 0.68) 100%);
    }


    .basket-btn {
        position: relative;

        @media(max-width: 767px) {
            display: flex;

            svg {
                display: block;
                height: 18px;
                width: auto;

                .counter {
                    width: 18px;
                    height: 18px;
                    bottom: -30%;
                    font-size: 11px;
                    right: -1%;
                }
            }
        }

        .counter {
            position: absolute;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #cba674;
            border-radius: 50%;

            color: #ffffff;
            font-family: "FuturaPT";
            font-size: 14px;
            line-height: .8;
            font-weight: 400;
            bottom: -33%;
            right: -11%;
        }
    }

    .basket,
    .favorite {
        margin-right: 15px;

        @media(max-width: 767px) {
            display: flex;
            margin: 0;

            svg {
                display: block;
                height: 18px;
                width: auto;
            }
        }

    }

    .account,
    .favorite,
    .basket,
    .currency,
    .search,
    .lang,
    .catalog-trigger {

        &>button,
        &>a {
            background: transparent;
            border: none;
            outline: none;
        }
    }

    .row {
        display: flex;
        align-items: center;
    }

    .logo {
        text-align: center;
    }



    .catalog {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        background: #fff;
        box-shadow: 0 4px 7px 1px rgba(0, 0, 0, 0.1);
        opacity: 0;
        pointer-events: none;
        transform: translateY(15px);
        transition: .3s;

        @media(max-width: 767px) {
            top: 76px;
        }

        @media(max-width: 680px) {
            bottom: 0;
            height: calc(100vh - 76px);
            overflow: auto;
        }

        @media(max-width: 460px) {
            height: calc(100vh - 63px);
            top: 63px;
        }

        &.active {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);

            .category-item {

                &.hover,
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #313131;

                    .fa {
                        opacity: 1;
                    }

                    &~.sub-category {

                        opacity: 1;
                        pointer-events: auto;
                        display: block;
                        @media(min-width: 681px){
                           overflow: auto;
    height: 625px; 
                        }
                        

                    }
                }
            }
        }

        .row {
            align-items: flex-start;
        }

        .category {
            padding: 10px 0 23px;
            a{
                text-decoration: none;
                &:hover, &:focus{
                    text-decoration: none;
                }                
            }
            .category-item {
                display: flex;
                align-items: center;
                color: #5e5e5e;
                font-size: 20px;
                font-weight: 300;
                line-height: 50px;
                max-width: 271px;
                font-family: "FuturaPT";
                font-weight: 400;
                letter-spacing: 0.5px;
                transition: .3s;

                @media(max-width: 680px) {
                    max-width: 100%;
                }

                .fa {
                    margin-left: auto;
                    color: #9d74a0;
                    opacity: 0;
                    transition: .3s;
                }

                &.hover,
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #313131;

                    .fa {
                        opacity: 1;
                    }

                }

                span {
                    margin-right: 15px;
                }

                img {
                    display: block;
                }
            }
        }

        .sub-item-category {
            box-shadow: 0 4px 7px 1px rgba(0, 0, 0, 0.1);
            background-color: #FAF4FA;
            width: 100%;
            display: none;
            a{
                text-decoration: none;
                &:hover, &:focus{
                    color: #9d74a0;
                text-decoration: none;
                }
            }
            .sub-item {
                padding-left: 81px;
                color: #5e5e5e;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 50px;
                letter-spacing: 0.4px;
                transition: .3s;
                a{
                    text-decoration: none;
                    &:hover, &:focus{
                        color: #9d74a0;
                    text-decoration: none;
                    }
                }
                &:hover {
                    a {
                        color: #9d74a0;
                        text-decoration: none;
                        
                    }

                }

            }
        }

        .category-img {
            padding-top: 80px;
            position: relative;

            &::after {
                content: "";
                width: 1000px;
                height: 1000px;
                position: absolute;
                left: -56px;
                top: -90px;
                z-index: 0;
                background-color: #faf4fa;
            }

            &::before {
                content: "";
                position: absolute;
                height: 200px;
                width: 419px;
                background: url(../img/pics/decor-element.png);
                z-index: 1;
                right: -39%;
                top: 12%;
            }

            img {
                position: relative;
                z-index: 2;
            }
        }

        .catalog-img {
            @media(max-width: 991px) {
                display: none;
            }
        }

        .sub-category {
            padding: 10px 0 23px;
            box-shadow: 0 4px 7px 1px rgba(0, 0, 0, 0.1);
            position: absolute;
            z-index: 3;
            background: #fff;
            width: 371px;
            right: 0;
            top: 0;
            opacity: 0;
            pointer-events: none;

            @media(max-width: 1199px) and (min-width: 992px) {
                width: 291px;
            }

            @media(max-width: 680px) {
                position: static;
                width: 100%;
                display: none;
            }

            .sub-category-item-wr {}

            .sub-category-item {
                flex-wrap: wrap;
                display: flex;
                align-items: center;

                &:hover {
                    &>a {
                        color: #9d74a0;
                        text-decoration: none;
                    }
                }

                a {
                    transition: .3s;
                    display: flex;
                    align-items: center;
                    color: #5e5e5e;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 50px;
                    /* color: #313131; */
                    font-family: "FuturaPT";
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    width: 100%;
                }

                span {
                    margin-left: 51px;
                    margin-right: 15px;
                }

                img {
                    display: block;
                }
            }
        }
    }

    .catalog-container {
        overflow: hidden;
        min-height: 625px;
    }
}

.empty-basket {
    position: absolute;
    right: 5px;
    min-width: 251px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, .17);
    border-radius: 5px;
    background-color: #fff;
    padding: 22px 26px;
    top: calc(100% + 22px);
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    transform: translateY(15px);

    &.active {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
    }

    li {}

    p {
        margin: 0;
        display: flex;
        align-items: center;
    }

    span {
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 18px;
        font-weight: 300;
        line-height: 1.15;
        letter-spacing: 0.45px;
    }

    img {
        margin-right: 16px;
    }
}

.scrolled-line {
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 0;

    .gradient-line {
        width: 100%;
        height: 6px;
        background-color: #412f44;
        background-image: linear-gradient(0deg, rgba(83, 91, 94, .09), hsla(0, 0%, 100%, .09) 10%, rgba(78, 82, 84, .09) 51%, hsla(0, 0%, 99%, .09) 84%, rgba(83, 91, 94, .09)), linear-gradient(0deg, rgba(101, 51, 96, .53), rgba(140, 96, 144, .53));
    }

    .scroll-line {
        padding: 4px 15px 6px;
        text-align: center;
        background-color: #c19d6c;
        background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%);

        span {
            color: #ffffff;
            font-family: "PoiretOne";
            font-size: 22px;
            line-height: 1.15;

            @media(max-width: 767px) {
                font-size: 18px;
            }
        }
    }

}

.logo-col {
    @media(max-width: 767px) {
        padding: 0;
    }

    .logo {
        @media(max-width: 767px) {
            a {
                max-width: 80px;

                img {
                    max-width: 100%;
                }

            }
        }

        @media(max-width: 460px) {
            a {
                max-width: 56px;

            }
        }
    }
}

.sub-category-item{
    a{
        &:focus{
          &+.sub-item-category{
        display: block;
       }  
        }
       } 
        &:hover{
           .sub-item-category{
               display: block;
           }
           
           
        }
    
}
.tickets{
    font-size: calc(0.25vw + 5px);
    padding-left: 13em; 
    position: relative;
    background: #ffffff;
    p{
        margin: 0;
    }
    button{
        border: none;
        background: none;
        outline: none;
        padding: 0;
        cursor: pointer; 
        padding: 1.2em 2.4em;
        display: block;
    }
    .caption{
        margin-bottom: 6em;
        @media(max-width: 991px) {
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
            padding: 7px 15px;
            text-align: center;
            background-color: #97729b;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            &::before{
                content: "←";
                position: absolute;
                left: 15px;
                top: 0;
                bottom: 0;
                margin: auto;
                font-size: 21px;
                display: flex;
                color: #fff;
                align-items: center;
            }
        }

        span {
            color: #313131;
            font-family: "PoiretOne";
            font-size: 3em;
            font-weight: 400;
            @media(max-width: 991px){
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: 0.4px;
            }
        }
    }
    .create-ticket{
        padding-left: 4em;
        background-color: #fafafa;
        margin-bottom: 9em;
        @media(max-width: 800px){
            display: none;
        }
    }
    .create-ticket-button{
        display: none;
        margin: 3em 0;
        button{
            border-radius: 3px;
            border: 1px solid #9d74a0;
            margin: 0 auto;
            span{
                color: #9d74a0;
                font-family: "FuturaPT";
                font-size: 1.6em;
                font-weight: 400;
            }
        }
        
        @media(max-width: 800px){
            display: block;
        }
    }
    .create-ticket-caption{
        margin-bottom: 2.8em;
        p{
            color: #313131;
            font-family: "FuturaPT";
            font-size: 2.4em;
            font-weight: 300;
        }
    }
    form{
        max-width: 590px;
        
        span, input, textarea{
            color: #6f7680;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 300;
        }
        input, textarea{
            width: 70%;
            margin-left: auto;
            border-radius: 3px;
            border: 1px solid #e6e6e6;
            resize: none;
            outline: none;
            padding: 1em 1.6em;
        }
        textarea{
            min-height: 7em;
        }
        .submit{
            border-radius: 3px;
            background-color: #9d74a0;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            span{
                color: #ffffff;
            }
        }
        .reset{
            span{
                color: #865b87;
            }
        }
    }
    label{
        width: 100%;
        display: flex;
        margin: 0;
        margin-bottom: 2em;
    }
    .button-wrap{
        width: fit-content;
        display: flex;
        margin-left: auto;
    }
    @media(max-width: 1200px){
        font-size: calc(0.25em + 7px);
    }
    @media(max-width: 990px){
        padding-left: 0;
        .tickets-table, form{
            max-width: none;
        }
    }
    @media(max-width: 800px){
        font-size: calc(0.25em + 9px);
    }
}
.pagination-block {
    display: flex;
    justify-content: space-between;
    max-width: 420px;
    width: 100%;
    align-items: center;
    padding: 0;
    .pagination-prev, .pagination-next {
width: 51px;
height: 41px;
border-radius: 3px;
background-color: #9d74a0;
background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
display: flex;
align-items: center;
justify-content: center;   
}

    .fa {
        color: #fff;
        font-size: 30px;
        line-height: 30px;
        line-height: 29px;
    }

    span {
        color: #4a4a4a;
font-family: "FuturaPT";
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0.54px;
    }

    li {
        list-style: none;
        &.active{
            a{
               color: #9d74a0; 
               &::after{
                   opacity: 1;
               }
            }
             
          }
        a {
color: #4a4a4a;
position: relative;
font-family: "FuturaPT";
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0.54px;
&::after{
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 2px;
    background: #9d74a0;
    opacity: 0;
}
&:hover, &:focus{
    text-decoration: none;
}



        }
    }

    .pagination-next{
        
    }
}
.faq-page{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 7em;
    @media(max-width: 920px){
        font-size: calc(0.25vw + 8px);
    }
    .link{
        p{
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 1.6em;
        }
        a{
            text-decoration: none;
            color: #9d74a0; 
        }
        span{
            color: #7e7e7e;
        }
    }
    .caption{
        margin-bottom: 2.5em;
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 4.6em;
            text-align: center;
            @media(max-width: 600px){
                font-size: 3em;
            }
        }
    }
    .separator{
        width: 1px;
        height: 4em;
        background-color: #9d74a0;
        margin: 0 auto 2.5em;
    }
    .faq-content{
        float: none;
        margin: 0 auto;
        p{
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 300;
            letter-spacing: 0.16px;
        }
        .descript{
            margin-bottom: 3em;
        }
        .question{
            margin-bottom: 2em;
            p{
                color: #2a3137;
                font-family: "PoiretOne";
                font-size: 2.2em;
                font-weight: 400;
                letter-spacing: 0.22px; 
            }
        }
        .answer{
            margin-bottom: 4em;
            padding: 3em 4em;
            border-radius: 5px;
            background-color: #f5e9f5;
        }
        .pages{
            display: flex;
            margin: 0 auto;
            width: fit-content;
            align-items: center;
            @media(max-width: 600px){
                width: 100%;
                justify-content: space-between;
            }
            .number{
                border: none;
                outline: none;
                background: transparent;
                padding: 0;
                padding: 0 10px;
                @media(max-width: 600px){
                    padding: 0;
                }
                span{
                    color: #4a4a4a;
                    font-family: "FuturaPT";
                    font-size: 1.8em;
                    font-weight: 400;
                    letter-spacing: 0.54px;
                    &.active{
                        color: #9d74a0;
                        border-bottom: 2px solid #9d74a0;
                    }
                } 
            }
            .arrow{
                text-align: center;
                vertical-align: middle;
                border: none;
                outline: none;
                padding: 1em 2em;
                border-radius: 3px;
                background-color: #9d74a0;
                background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
                i{
                    font-size: 2em;
                    color: #ffffff;
                }
            }
        }
    }
}
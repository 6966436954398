.standart-btn{
    width: 100%;
    padding: 17px;
    border: none;
border-radius: 3px;
background-color: #9d74a0;
background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);


color: #ffffff;
font-family: "FuturaPT";
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.4px;
}

.standart-btn-empty{
width: 100%;
    padding: 17px;
border-radius: 3px;
border: 1px solid #9d74a0;

background: transparent;
color: #9d74a0;
font-family: "FuturaPT";
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.4px;
}

.hidden-content{
    display: none;
}
.d-none{
    display: none;
}
.breadcrumb{
    width: 100%;
    padding: 0 15px;
    background: transparent;
    margin: 30px 0;
    @media(max-width: 750px){
        margin: 15px 0;
        padding: 0;
    }
    a{
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 16px;
        font-weight: 300;
        transition: .3s;
        text-decoration: none;
        &:hover{
            color: #9d74a0;
        }
        
    }
    li+li::before{
        content: "";
        display: none;
    }

    i{
        display: none;
        font-size: 16px;
        color: #7e7e7e;
        margin: 0 5px;
    }
    li+li i{
        display: inline-block;
        
    }
}
.adaptive-display{
    @media(max-width: 991px){
        display: none;
    } 
}
.mobile-show{
    display: none;
    @media(max-width: 991px){
        display: block;
    }
}

.transparent-lnk{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.pc-busked {
    width: 80%;
    margin: 0 auto;
    @media(max-width: 991px) {
        width: 100%;
    }
    .counter-wr{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 82px;
        width: 100%;
        margin-right: 2.5em;
        @media(max-width: 420px){
            margin-right: 0;  
            margin-bottom: 7px;          
        }
    }
    .caption {
        margin-bottom: 2.7em;

        @media(max-width: 991px) {
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
            padding: 7px 15px;
            text-align: center;
            background-color: #97729b;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
        &::before{
            content: "←";
            position: absolute;
            left: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            font-size: 21px;
            display: flex;
            color: #fff;
            align-items: center;

        }
        }

        span {
            color: #313131;
            font-family: "PoiretOne";
            font-size: 3em;
            font-weight: 400;
            @media(max-width: 991px){
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: 0.4px;
            }
        }
    }

    .column-1 {
        width: 25%;
        @media(max-width: 420px){
            width: 100px;            
        }
    }

    .column-2 {
        width: 50%;
        display: flex;
        @media(max-width: 420px){
            width: 100px;            
        }
    }

    .busket-header,
    .busket-footer {
        display: flex;
        margin: 5px 0;

        .column-1,
        .column-2 {
            @media(max-width: 420px){
                width: 100px;            
            }
            span {
                color: #6f7680;
                font-family: "FuturaPT";
                font-size: 1.6em;
                font-weight: 300;
            }

            &.price {
                span {
                    color: #9d74a0;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
    }

    .busked-item {
        display: flex;
        align-items: center;
        padding: 1.5em 0;
        border-top: 1px solid #e6e6e6;

        &:last-child {
            border-bottom: 1px solid #e6e6e6;
        }
    }

    .item-text {
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 14px;
        font-weight: 300;
    }

    .busked-counter {
        @media(max-width: 420px){
            flex-direction: column;
            align-items: flex-start;
        }
        button {
            background: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
        }

        .arrow {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #bababa;
            /* margin-left: 5px; */

            /* border: 1px solid red; */
            i {
                font-size: 10px;
                color: #bbbbbb;
            }
        }

        .next {
            /* margin-right: 2.5em; */
        }

        .counter {
            color: #6f7680;
            font-size: 13px;
        }

        .delete {
            color: #7e7e7e;
            text-decoration: underline;
        }
    }

    .price {
        span {
            color: #9d74a0;
            font-size: 1.6em;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .busket-footer {

        .column-1,
        column-2 {
            @media(max-width: 420px){
                width: 100px;            
            }
            span {
                font-size: 1.8em;
            }
        }
    }

    .last {
        text-align: right;
        @media(max-width: 420px){
            width: calc(100% - 200px);            
        }
    }

}
footer {
    
    font-size: calc(0.25vw + 5px);
    background-image: linear-gradient(0deg,rgba(83,91,94,.09) 0,hsla(0,0%,100%,.09) 10%,rgba(78,82,84,.09) 51%,hsla(0,0%,99%,.09) 84%,rgba(83,91,94,.09)),linear-gradient(0deg,rgba(101,51,96,.53) 0,rgba(140,96,144,.53));
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
background: #412f44;
z-index: -1;
    }
    .container {
        padding-top: 3.5em;
        @media(max-width: 991px){
            padding-top: 4.9em;
        }
    }

    .column {
        display: flex;
        @media(max-width: 991px){
            display: flex;
            font-size: 1.6em;
            padding: 0 36px;
        }
    }
    .logo{
        @media(max-width: 991px){
            display: none;
        }
    }
    .schedule {
        justify-content: center;
        @media(max-width: 991px){
           justify-content: flex-start;
           margin-bottom: 30px;
        }
    }

    .contacts {
        justify-content: flex-end;
        @media(max-width: 991px){
            justify-content: flex-start;
         }
         @media(max-width: 991px) and (min-width: 600px){
            width: 280px;
        }
    }
    .footer-nav{
        @media(max-width: 991px){
            margin-bottom: 31px;
        }
        @media(max-width: 991px) and (min-width: 600px){
            width: 280px;
        }
    }
    .destop-txt{
        display: none;
        @media(min-width: 992px){
            display: inline;
        }
    }
    .mobile-txt{
        display: inline;
        @media(min-width: 992px){
            display: none;
        }
    }
    .footer-wr{
        @media(max-width: 991px) and (min-width: 600px){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
.footer-item{
    @media(max-width: 991px){
        margin-bottom: 10px;
    }
}
    a {
        text-decoration: none;
        outline: none;
    }

    p,
    a {
        color: #f9f9f9;
        font-size: 1.8em;
        font-family: "FuturaPT";
        font-weight: 300;
        @media(max-width: 991px){
            line-height: 1.46;
            font-size: 1.9em;
        }
    }
    .descr {
        @media(max-width: 991px){
            margin-bottom: 32px;
        }
    
    p{
        font-weight: 400;
        span{
            @media(max-width: 991px){
                display: block;
            }
        }
    }
}

    .caption{ 
        @media(max-width: 991px){
margin-bottom: 13px;
        }
    
    p {
        font-size: 2.2em;
        font-weight: 400;
    }
}
    .for-icon {
        position: relative;

        p,
        a {
            padding-left: 1.5em;
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1.8em;
            color: #cca773;
        }
    }

    .social {
        position: relative;
        display: flex;
        max-width: 120px;
        justify-content: space-between;
        margin-top: 2.5em;
        @media(max-width: 991px){
            max-width: 164px;
    margin-top: 4.5em;
        }
        .icon {
            font-size: 2.6em;
            color: #cca773;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .develop {
        margin-top: 5em;
        background-color: #412f44;
        text-align: center;

        p {
            margin: 0;
            font-size: 1.6em;
            padding: 10px 0;
            letter-spacing: 0.4px;
            @media(max-width: 991px){
                font-size: 2.9em;
            }
        }
    }
}
.payment-info{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 7em;
    @media(max-width: 1200px){
        font-size: calc(0.25vw + 7px);
    }
    @media(max-width: 1200px){
        font-size: calc(0.25vw + 9px);
    }
    .link{
        p{
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 1.6em;
        }
        a{
            text-decoration: none;
            color: #9d74a0; 
        }
        span{
            color: #7e7e7e;
        }
    }
    .caption{
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 4.6em;
            text-align: center;
            @media(max-width: 420px){
                font-size: 4em;
            }
        }
    }
    .separator{
        width: 1px;
        height: 4em;
        background-color: #9d74a0;
        margin: 0 auto 2.5em;
    }
    .content{
        margin: 0 auto;
        float: none;
    }
    p{
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 1.6em;
        font-weight: 300;
        letter-spacing: 0.16px;
    }
    section{
        p{
            padding-left: 20px;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 7px;
                height: 7px;
                background-color: #865b87;
                border-radius: 50%; 
            }
        }
    }
    .section-caption{
        p{
            color: #2a3137;
            font-size: 3em;
            letter-spacing: 0.75px;
        }
        
    }
}
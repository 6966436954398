.cabinet{
    font-size: calc(0.25em + 5px);
    padding-top: 100px;
    padding-bottom: 14em;
    @media(max-width: 1200px){
        font-size: calc(0.25em + 6px);
    }
    
    @media(max-width: 991px){
        background: #fff;
        position: relative;
        z-index: 1000;
        padding-top: 0;
        width: 100%;
    }
    .cabinet-menu{
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        .head-menu{
            background-color: #bf9760;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%);
            padding: 1.5em 5em;
            /* cursor: pointer; */
            position: relative;
            .icon{
                position: absolute;
                top: 1.7em;
                left: 2em;
            }
            span{
                color: #ffffff;
                font-family: "PoiretOne";
                font-size: 1.8em;
                font-weight: 400;
                letter-spacing: 0.18px;
            }
            i{
                color: #ffffff; 
                font-size: 1.8em;
            }
        }
        .cab-menu-item{
            padding: 15px 5em;
            outline: none;
            border: none;
            width: 100%;
            display: block;
            text-decoration: none;
            background: transparent;
            transition: .3s;
            position: relative;
            text-align: left;
            &:hover{
                background: #9d74a0;
                span{
                    color: #ffffff;
                }
            }
            span{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
                transition: .3s;
            }
            i{
                font-size: 1.8em;
                margin-left: auto; 
                color: #ffffff;
            }
            .arrow{
                position: absolute;
                top: 2.1em;
                right: 2em;
            }
        }
    }
    .personal-data{
        width: 80%;
        margin: 0 auto; 
        @media(max-width: 991px) {
            width: 100%;
        }
        .caption{
            margin-bottom: 2em;
            @media(max-width: 991px) {
                width: calc(100% + 30px);
                position: relative;
                left: -15px;
                padding: 7px 15px;
                text-align: center;
                background-color: #97729b;
                background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            &::before{
                content: "←";
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 21px;
        display: flex;
        color: #fff;
        align-items: center;
    
            }
            }
            span{
                color: #313131;
                font-family: "PoiretOne";
                font-size: 3em;
                font-weight: 400;
                @media(max-width: 991px){
                    color: #ffffff;
                    font-family: "FuturaPT";
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.65;
                    letter-spacing: 0.4px;
                                }
            }
        }
        legend{
            color: #313131;
            font-family: "FuturaPT";
            font-size: 2.4em;
            font-weight: 300;
            border: none;
            padding-top: 1.1em;
            @media(max-width: 420px){
                padding-top: 0;
            }
        }
        .adress{
            @media(max-width: 420px){
                margin-bottom: 10px;
            }
            span{
                color: #5b5b61;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
                &.nuber-icon{
                    color: #9f77a2;
                    border-radius: 50%;
                    border: 1px solid #9d74a0;
                    color: #9f77a2;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    margin-right: 15px;
                    text-align: center;
                    display: inline-block;
                    @media(max-width: 420px){
                        width: 19px;
                    height: 19px;
                    font-size: 11px;
                    line-height: 15px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    }
                }
            }
        }
        label{
            color: #5b5b61;
            font-family: "FuturaPT";
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.45px;
            width: 100%;
            margin-bottom: 10px;
            display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 420px){
        margin-bottom: 30px;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
    }
            span{
                color: #f16e7d;
            }
            input{
                float: right;
                min-width: 270px;
                padding: 10px 20px;
                font-size: 0.7em;
                border-radius: 3px;
                border: 1px solid #e6e6e6;
                outline: none;
                @media(max-width: 420px){
                    width: 100%;
                }
            }
        }
        fieldset{
            border-bottom: 1px solid #e6e6e6;
            @media(max-width: 420px){
                margin-bottom: 25px;
            }
            &:first-child{
                border-top: 1px solid #e6e6e6;
            }
            button{
                display: block;
                margin-left: auto;
                background: transparent;
                border: none;
                outline: none;
                margin-bottom: 2.5em;
                padding: 0;
                span{
                    color: #9d74a0;
                    font-family: "FuturaPT";
                    font-size: 1.6em;
                    font-weight: 400;
                }
            }
            .new-pass{
                margin-bottom: 2.5em;
            }
            a{
                color: #9d74a0;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 400;
                text-decoration: none;
            }
        }
        .submit{
            border: none;
            outline: none;
            padding: 8px 3em;
            margin-top: 2.5em;
            margin-left: auto;
            display: block;
            border-radius: 3px;
            background-color: #9d74a0;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            @media(max-width: 420px){
                width: 100%;
            }
            span{
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 1.6em;
                font-weight: 300;
            }
        }
    }
}
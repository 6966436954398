.pc-my-order{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20em;
    @media(max-width: 991px){
        width: auto;
    }
    @media(max-width: 800px){
        font-size: calc(0.25vw + 8px);
    }
    .caption{
        margin-bottom: 2.7em;
        @media(max-width: 991px) {
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
            padding: 7px 15px;
            text-align: center;
            background-color: #97729b;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            &::before{
                content: "←";
                position: absolute;
                left: 15px;
                top: 0;
                bottom: 0;
                margin: auto;
                font-size: 21px;
                display: flex;
                color: #fff;
                align-items: center;
            }
        }

        span {
            color: #313131;
            font-family: "PoiretOne";
            font-size: 3em;
            font-weight: 400;
            @media(max-width: 991px){
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: 0.4px;
            }
        }
    }
    .column{
        width: 16.6%;
    }
    .column-2{
        width: 33.2%;
        @media(max-width: 700px){
            text-align: right;
        }
    }
    .my-order-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:  1.5em 0;  
    
        .item-text{
            color: #7e7e7e;
            font-size: 1.6em;
            font-family: "FuturaPT";
            font-weight: 400;
        }
        .name{
            text-align: right;
            span{
                font-family: "PoiretOne";
            }
        }
        .quantity{
            span{
                font-weight: 300;
            }
            @media(max-width: 700px){
                text-align: right;
            }
        }
        .price{
            @media(max-width: 700px){
                text-align: right;
            }
            span{
                color: #9d74a0;
                text-transform: uppercase;
            }
        }
    }
    .empty{
        @media(max-width: 700px){
            display: none;
        }
    }
    .order-list{
        width: 100%;  
    }
    .o-l-item{
        border-bottom: 1px solid #e6e6e6;
        &:first-of-type{
            border-top: 1px solid #e6e6e6;
        }
    }
    .order-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5em 0;
        
        .main-text{
            color: #606771;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 400;
        }
        .item-price{
            color: #606771;
            text-transform: uppercase;
        }
        .order-number{
            background: transparent;
            border: none;
            outline: none;
            padding: none;
            padding: 0;
            text-align: left;
            span{
                color: #9d74a0;
            }
            i{
                color: #9d74a0;
                font-size: 1.6em;
                margin-left: 0.1em;
            }
        }
        .send{
            font-size: 1.4em;
            color: #c19d6a;
        }
        .received{
            font-size: 1.4em;
            color: #9d74a0;
        }
        .denied{
            font-size: 1.4em;
            color: #e24651;
        }
    }
    .my-order-item{
        display: none;
    }
    .open{
        .my-order-item{
            display: flex;
        }
    }
    .adaptive-container{
        display: none;
        @media(max-width: 700px){
            display: flex;
        }
    }
    .adaptive-none{
        @media(max-width: 700px){
            display: none;
        }
    }
    .column-wrap{
        width: 83.4%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media(max-width: 700px){
            flex-direction: column;
        }
    }
    .column-third{
        width: 33%;
        text-align: right;
    }
}
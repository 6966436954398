.product-final{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 10em;
    @media(max-width: 1300px){
        font-size: calc(0.25vw + 7px);
    }
    p{
        margin: 0;
    }
    img{
        width: 100%;
    }
    button{
        border: none;
        background: transparent;
        outline: none;
        padding: 0;
        cursor: pointer;
    }
    .img-wrap{
        width: 100%;
        border: 1px solid #e6e6e6;
        padding: .7em;
        margin-bottom: 1em;
        display: block;
        @media(max-width: 768px){
            width: 25%;
            margin-right: 15px;
        }
    }
    .col-container{
        @media(max-width: 768px){
            display: flex;
            flex-direction: column-reverse;
        }
    }
    .img-wrap-container{
        @media(max-width: 768px){
            display: flex;
        }
    }
    .img-wrap-big{
        border: 1px solid #e6e6e6;
        padding: 5em;
        margin: 0 0 4em -15px;
        @media(max-width: 768px){
            margin-bottom: 15px; 
            margin-left: 0;
        }
    }
    .product-information{
        margin-bottom: 5em;
    }
    .section-caption{
        p{
            color: #313131;
            font-family: "FuturaPT";
            font-size: 4.6em;
            font-weight: 300;
            letter-spacing: 1.15px;
            text-align: center;
            margin-bottom: 1.2em;
        }
        .separator{
            width: 1px;
            height: 4.8em;
            background-color: #9d74a0;
            margin: 0 auto 3em;

        }
    }
    .product-description{
        .descript-caption{
            margin-bottom: 1.5em;
            p{
                color: #313131;
                font-family: "PoiretOne";
                font-size: 4.6em;
                font-weight: 400; 
            }
        }
        .art{
            
            p{
                color: #a9a9a9;
                font-family: "Futura PT - Book";
                font-size: 16px;
                font-weight: 400;
            }
        }
        .status{
            margin-bottom: 2.5em;
            p{
                color: #70c54f;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 400;
            }
        }
        .price{
            margin-bottom: 2.7em;
            p{
                color: #9d74a0;
                font-family: "FuturaPT";
                font-size: 26px;
                font-weight: 400;
                text-transform: uppercase;
                span{
                    color: #a9a9a9;
                    font-size: 18px;
                    text-decoration: line-through;
                } 
            }
        }
        .button-wrap{
            display: flex;
            margin-bottom: 3.6em;
        }
        .to-basked{
            padding: 1em 4.5em;
            border-radius: 3px;
            background-color: #9d74a0;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%); 
            span{
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
            }
            i{
                font-size: 1.8em; 
                color: #ffffff;
                margin-left: .6em;
            }
        }
        .to-favorite{
            padding: 1em 4.5em;
            border-radius: 3px; 
            span{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
            }
            i{
                font-size: 1.8em; 
                color: #7e7e7e;
                margin-left: .6em;
            }
        }
        .desscription{
            margin-bottom: 4em;
            p, span{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 300;
                margin-bottom: 1.5em;
                &.product-title{
                    color: #313131;
                    font-weight: 400;
                }
            }
            
            .haracter-list{
                display: flex;
                flex-wrap: wrap;
                .li-half{
                    width: 50%;
                    margin-bottom: 2.2em;
                }
            }
            .all-haracter{
                span{
                    color: #398bff;
                    font-family: "FuturaPT";
                    font-size: 1.8em;
                    font-weight: 400;
                }
                i{
                    font-size: 1.8em; 
                    color: #398bff;
                }
            }
        }
        .detal{
            a{
                color: #313131;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 500;
                text-decoration: none;
            }
        }
    }
    .art-and-stars{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5em;
        @media(max-width: 600px){
            display: none;
        }
        i{
            color: #9d74a0;
            font-size: 1.6em;
        }
        .review-quantity{
            margin-left: .7em;
            color: #398bff;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 400;
            text-decoration: underline;
        }
    }
    .stars-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
    }
    .stars-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            margin-bottom: 0;
            margin-right: 5px;
            display: flex;
        }

        .stars-item {
            display: flex;

            &:hover,
            &.active {
                .fa-star-o {
                    display: none;
                }

                .fa-star {
                    display: block;
                }

                &~.stars-item {
                    .fa-star-o {
                        display: none;
                    }

                    .fa-star {
                        display: block;
                    }
                }
            }

        }

        .fa-star {
            display: none;
        }
    }
    .review-title{
        margin-bottom: 2.4em;
        @media(max-width: 600px){
            text-align: center;
        }
        p{
            color: #313131;
            font-family: "FuturaPT";
            font-size: 3.6em;
            font-weight: 300;
        }
    }
    .review{
        margin: 0 0 5em;
    }
    .review-item{
        padding: 3em;
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        margin-bottom: 2em;
        p{
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 400;
        }
        .name{
            margin-right: 2em;
            p{
                color: #313131; 
            }
        } 
        .stars{
            i{
                color: #9d74a0;
                font-size: 1.6em;
            }
        }
        .name-wrap{
            display: flex;
            align-items: center;
            margin-bottom: 2em;
        }
        .date{
            margin-left: auto;
            p{
                color: #b6b6b6;
                font-size: 14px;
            }
            @media(max-width: 600px){
                display: none;
            }
        }
        .comment-wrap{
            color: #7e7e7e;
            font-weight: 200;
        }
    }
    .related-products{
        margin-bottom: 3em; 
    }
    .form-caption{
        margin-bottom: 2em;
        @media(max-width: 600px){
            display: none;
        }
        p{
            color: #313131;
            font-family: "FuturaPT";
            font-size: 2.4em;
            font-weight: 400;
        }
    }
    .create-new-review{
        padding: 3em;
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        
        label{
            display: flex;
            align-items: center; 
            @media(max-width: 600px){
                display: none;
            }
            span{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 200;
                margin-right: 1.5em;
            }
            .stars{
                i{
                    color: #d2d2d2;
                    font-size: 1.8em;
                }
            }
        }
        .form-section{
            display: flex;
            margin-bottom: 2em;
            @media(max-width: 600px){
                display: none;
            }
        }
        input{
            border-radius: 3px;
            border: 1px solid #d2d2d2;
            width: calc(50% - 0.6em);
            max-width: 170px;
            display: block;
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 300;
            margin-right: 1.2em;
            padding: .6em 1.2em;
            outline: none;
            &:last-of-type{
                margin-right: 0;
            } 
            &::placeholder{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1em;
                font-weight: 300;
            }
        }
        textarea{
            border-radius: 3px;
            border: 1px solid #d2d2d2;
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 300;
            padding: 1em;
            resize: none;
            outline: none;
            width: 100%;
            min-height: 105px;
            &::placeholder{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1em;
                font-weight: 300;
            }
            @media(max-width: 600px){
                display: none;
            }
        }
        .add-review{
            margin-top: 2em;
            border-radius: 3px;
            background-color: #9d74a0;
            display: flex;
            align-items: center;
            padding: 1em 2em;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            span{
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 1.8em;
                font-weight: 400;
            }
            .plus{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.8em;
                height: 1.8em;
                /* background: #313131; */
                border: 1px solid #ffffff;
                border-radius: 50%;
                margin-left: .5em;
            }
            @media(max-width: 600px){
                margin: 2em auto 0; 
            }
        }
    }
    .services-header{
        background-color: #f5f5f5;
        display: flex;   
        padding: 0 4em; 
    }
    .services-button{
        width: 33%;
        padding: 2em 0;
        span{
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 300; 
        }
        &.selected{
            border-top: 2px solid #9d74a0;
            span{
                color: #313131;
            }
        }
        &:first-of-type{
            text-align: left;
        }
        &:last-of-type{
            text-align: right;
        }
    }
    .services-body{
        padding: 3em 4em;
        p{
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 300;
        }
        span{
            color: #313131;
            float: right;
        }
        .service-elem{
            margin-bottom: 1em;
        }
    }
}
.partners{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 7em;
    @media(max-width: 800px){
        font-size: calc(0.25vw + 6px);
    }
    .caption{
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 4.6em;
            text-align: center;
        }
    }
    .separator{
        width: 1px;
        height: 4em;
        background-color: #9d74a0;
        margin: 0 auto 2.5em;
    }
    .partner-item{
        margin-bottom: 6em;
        .item-name{
            margin: 2.5em 0 1.5em;
        }
        img{
            width: 100%;
        }
        p{
            color: #2a3137;
            font-family: "PoiretOne";
            font-size: 2.4em;
            font-weight: 400;
            letter-spacing: 0.6px;
        }
        a{
            color: #2f69c0;
            font-family: "FuturaPT";
            font-size: 2em;
            font-weight: 500;
            letter-spacing: 0.5px;
            text-decoration: none;
        }
    }
}
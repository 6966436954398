.pc-comments{
    width: 80%;
    margin: 0 auto;
    @media(max-width: 1400px){
        font-size: calc(0.25vw + 6px);
    }
    @media(max-width: 1200px){
        font-size: calc(0.25vw + 7px);
    }
    @media(max-width: 991px){
        width: 100%;
    }
    @media(max-width: 800px){
        font-size: calc(0.25vw + 8px);
    }

    .caption{
        margin-bottom: 6em;
        @media(max-width: 991px) {
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
            padding: 7px 15px;
            text-align: center;
            background-color: #97729b;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
            &::before{
                content: "←";
                position: absolute;
                left: 15px;
                top: 0;
                bottom: 0;
                margin: auto;
                font-size: 21px;
                display: flex;
                color: #fff;
                align-items: center;
            }
        }

        span {
            color: #313131;
            font-family: "PoiretOne";
            font-size: 3em;
            font-weight: 400;
            @media(max-width: 991px){
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: 0.4px;
            }
        }
    }
    .comment-item{
        padding: 1.5em 0;
        border-top: 1px solid #e6e6e6;
        &:last-of-type{
            border-bottom: 1px solid #e6e6e6;
        }
        @media(max-width: 600px){
            &.open{
                .comment-message{
                    display: block;
                }
            }
            .comment-message{
                display: none;
            }
        }
        .comment-header-adaptive{
            display: none;
            @media(max-width: 600px){
                display: block;
            }
        }
        .comment-header-no-adaptive{
            @media(max-width: 600px){
                display: none;
            }
        }
    }
    .comment-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.7em;
    }
    .left-part{
        display: flex;
        .info{
            margin: 2em 0 .8em 2.5em;

            P{
                margin: 0;
                color: #313131;
                font-family: "PoiretOne";
                font-size: 1.6em;
                font-weight: 400;
            }
            span{
                color: #7e7e7e;
                font-family: "FuturaPT";
                font-size: 1.6em;
                font-weight: 300;
            }
            
        }
    }
    .item-date{
        span{
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 300;
        }
    }
    .comment-message{
        border-radius: 5px;
        background-color: #f4f4f4;
        padding: 2em 1.5em;
        margin-bottom: 1.7em;
        p{
            color: #606771;
            font-family: "FuturaPT";
            font-size: 1.6em;
            font-weight: 300;
        }
    }
}
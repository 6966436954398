main {
    font-size: calc(0.25vw + 5px);
    position: relative;
    padding-top: 5em;

    img {
        width: 100%;
    }

    .to-be {
        position: relative;
        margin-bottom: 9em;

        .to-be-item {
            position: relative;

            @media(max-width: 991px) {
                padding-top: 4.5em;
                font-size: 2.1em;
            }
        }

        .to-be-item:nth-child(1) {
            padding-top: 9em;

            @media(max-width: 991px) {
                padding-top: 8em;
            }
        }

        .to-be-item:nth-child(2) {
            padding-top: 4.5em;
        }

        .caption {
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 15px;

            p {
                font-size: 6em;
                letter-spacing: 0.05em;
                background-image: linear-gradient(to top, #653360 0%, #8c6090 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "FuturaPT";
                font-size: 60px;
                font-weight: 200;
                line-height: 1;
            }
        }

        .descript {
            position: absolute;
            bottom: 3.4em;
            left: 15px;
            right: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .wrap {
                width: 90%;
                margin: 0 auto;
            }

            p {
                text-align: right;
                font-size: 2.2em;
                color: #313131;
                background-clip: text;
                font-family: "PoiretOne";
                font-weight: 400;
            }

            .special {
                font-size: 3em;
                background-image: linear-gradient(to top, #653360 0%, #8c6090 100%);
                background-clip: text;
                color: #2a3137;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "GoodVibesPro";

            }
        }

        .to-be-item:nth-child(3) {
            .descript p {
                text-align: left;
            }

            .descript .special {
                letter-spacing: 0.9px;
            }
        }
    }

    .about-brend {
        padding: 0 15px;
        max-width: 775px;
        margin: 0 auto 8em;

        @media(max-width: 992px) {
            font-size: 1.3em;
        }

        p {
            font-size: 1.8em;
            text-align: justify;
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-weight: 200;
        }

        .caption {
            p {
                font-size: 4.6em;
                color: #2a3137;
                text-align: center;
                font-family: "FuturaPT";

            }
        }

        button {
            background: transparent;
            border: none;
            display: block;
            margin: 0 auto;
            outline: none;
            cursor: pointer;

            span {
                color: #9d74a0;
                font-size: 1.8em;
                font-family: "FuturaPT";
                font-weight: 500;
            }
        }
    }

    .offer {
        .description {

            @media(max-width: 567px) {

                font-family: "FuturaPT";
                font-size: 2.5em;
                line-height: 1.27;
                letter-spacing: 0.45px;
                text-align: center;
                margin-bottom: 4em;
            }
        }

        .col-md-6 {
            padding: 0;
        }

        .caption {
            font-size: 5em;
            color: #38404a;
            font-family: "PoiretOne";
            font-weight: 200;

            @media(max-width: 567px) {
                margin-bottom: .3em;
                text-align: center;

            }
        }

        .back-img {
            @media(max-width: 991px) {
                margin-bottom: 2em;
                display: none;
            }

            img {
                width: 110%;

                @media(max-width: 991px) {
                    width: 100%;
                }
            }
        }

        p {
            font-size: 2em;
            color: #9d74a0;
            letter-spacing: 0.5px;
            font-weight: 400;

        }
    }
}

.offer-week-wr {
    padding: 0 15px;
}

.offer-week {
    .owl-wrapper-outer {
        width: calc(100% + 2px);
        padding: 15px 0;
    }

    .owl-buttons {
        position: absolute;
        top: -40px;
        right: 20px;
        display: flex;

        @media(max-width: 567px) {
            right: 0;
            left: 0;
            justify-content: center;
        }
    }

    

    .owl-prev,
    .owl-next {
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 26px;
        height: 26px;
        border: 1px solid #9d74a0;
        background-color: #ffffff;
        color: #9d74a0;
        transition: .3s;

        @media(max-width: 567px) {
            margin: 0;
        }

        &:hover {
            background-color: #9d74a0;
            color: #ffffff;
        }

        .fa {
            margin: 0 0 2px 2px;
        }

    }

    .owl-prev {
        margin-right: -1px;
    }
}

.new-items {
    .owl-wrapper-outer {
        padding: 15px 0;
        width: calc(100% + 2px);

    }

    .owl-buttons {
        @media(max-width: 567px){
            display: none;
        }
        .owl-prev,
        .owl-next {
            font-size: 40px;
            position: absolute;
            top: 15px;
            bottom: calc(1.75em - 15px);
            margin: auto;
            height: 60px;
            color: #c6c6c6;

        }

        .owl-prev {
            left: 30px;
        }

        .owl-next {
            right: 30px;
        }
    }
}

.product-wr {
    font-size: 10px;
    height: 42em;

    @media(max-width: 991px) {
        height: auto;
    }
}




.novelty {
    margin-bottom: 9em;

    .col-md-3 {
        padding: 0;
        height: 45.4em;
    }

    .caption {
        margin: 7.6em 0 3em;
        color: #38404a;
        text-align: center;
        position: relative;
        &::after{
            content: '';
            width: 1px;
            height: 4.8em;
            background-color: #9d74a0;
            margin: 0 auto 3em;
            display: block;
        }
        p {
            font-size: 5em;
            font-family: "FuturaPT";
            font-weight: 200;
        }
    }
}

.descript-outer {
    overflow: hidden;
    height: 20em;
    margin-bottom: 2em;
    transition: .3s ease-in;
}
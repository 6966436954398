.pc-favorites{
    width: 80%;
    margin: 0 auto;
    @media(max-width: 1200px) {
        width: 100%;
    }
    .txt-wr{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        @media(max-width: 1199px){
            margin-right: 5px;
            width: 30%;
        }
        @media(max-width: 567px){
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            margin-left: 20px;
        }
    }
    .caption{
        margin-bottom: 2.7em;
        @media(max-width: 991px) {
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
            padding: 7px 15px;
            text-align: center;
            background-color: #97729b;
            background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
        &::before{
            content: "←";
            position: absolute;
            left: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            font-size: 21px;
            display: flex;
            color: #fff;
            align-items: center;

        }
        }
        span{
            color: #313131;
            font-family: "PoiretOne";
            font-size: 3em;
            font-weight: 400;
            @media(max-width: 991px){
                color: #ffffff;
                font-family: "FuturaPT";
                font-size: 16px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: 0.4px;
            }
        }
    }
    .favorites-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #e6e6e6;
        padding:  1.5em 0;
        &:last-child{
            border-bottom: 1px solid #e6e6e6;
        }
        
    }
    .item-text{
        
            color: #7e7e7e;
            font-size: 1.6em;
            font-family: "FuturaPT";
            font-weight: 400;
        
    }
    .name{
        @media(max-width: 567px){
            display: none;
        }
        .item-text{
            font-family: "PoiretOne";
        }
    }
    .availability{
        margin-left: 40px;
        @media(max-width: 1199px){
            margin-left: 5px;
        }
        @media(max-width: 567px){
            height: 19px;
            margin-bottom: 10px;
        }
        .item-text{
            font-weight: 300;
        }
    }
    .price{
        @media(max-width: 567px){
           height: 40px;
           display: flex;
           align-items: center;

        }
        .item-text{
            color: #9d74a0;
            text-transform: uppercase;
        }
    }
    .button-wrap{
        display: flex;
        align-items: center;
        @media(max-width: 567px){
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }
    button{
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
    }
    .delete-item{
        border: 1px solid #a4a4a4;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width: 567px){
            margin-bottom: 10px;
        }
        svg{
            fill: #a4a4a4;
            width: 40%;
        }
    }
    .buy{
        border-radius: 3px;
        background-color: #9d74a0;
        background-image: linear-gradient(to top, rgba(83, 91, 94, 0.09) 0%, rgba(254, 254, 254, 0.09) 10%, rgba(78, 82, 84, 0.09) 51%, rgba(253, 253, 253, 0.09) 84%, rgba(83, 91, 94, 0.09) 100%), linear-gradient(to top, rgba(101, 51, 96, 0.53) 0%, rgba(140, 96, 144, 0.53) 100%);
        margin-right: 1.8em;
        padding: 1em 2.5em;
        display: flex;
        align-items: center;
        @media(max-width: 567px){
            margin-right: 0;
            padding: 1em;
        }
        span{
            color: #ffffff;
            font-family: "FuturaPT";
            font-size: 1.8em;
            font-weight: 400;
            margin-right: 0.8em;
        }
        i{
            color: #ffffff;
            font-size: 1.8em;
        }
    }
}



.product-item {
    width: 100%;
    padding: 2em 10px 3em;
    border: 1px solid #eeeeee;
    transition: 0.3s;
    height: 38em;
    background: #fff;
    @media(max-width: 991px){
        height: auto;
    }
    .favorite,
    .look {
        border: none;
        background: transparent;
        padding: 0;
        @media(max-width: 500px){
            width: 15px;
            .fa{
                font-size: 1em;
            }
        }
    }
    .look {
        svg{
            max-width: 100%;
            width: 25px;
                height: auto;
            path{
                fill: #b6b6b6;
                
            }
        }
    }
    .stars-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        
    }

    .stars-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            margin-bottom: 0;
            margin-right: 5px;
            display: flex;
        }

        .stars-item {
            display: flex;

            &:hover,
            &.active {
                .fa-star-o {
                    display: none;
                }

                .fa-star {
                    display: block;
                }

                &~.stars-item {
                    .fa-star-o {
                        display: none;
                    }

                    .fa-star {
                        display: block;
                    }
                }
            }

        }

        .fa-star {
            display: none;
        }
    }

    &:hover {
        box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.16);
        height: 42em;
        @media(max-width: 991px){
            height: auto;
            box-shadow: none;
        }
        .stars {
            opacity: 1;
        }

        button {}
        .stars,.item-button {
            opacity: 1;
            transform: translateY(0);
        }
        .item-content {
            transform: translateY(0);

            
            
        }

    }

    p {
        text-align: center;
        font-size: 2em;
        color: #9d74a0;
        letter-spacing: 0.5px;
        font-weight: 300;
    }

    .stars {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2em;
        opacity: 0;
        transition: 0.3s;
        position: relative;
        z-index: 3;
        @media(max-width: 991px){
            opacity: 1;
        }
        @media(max-width: 500px){
            padding: 0;
        }
        i {
            font-size: 2em;
            color: #b6b6b6;
        }

        .stars-wrap {
            i {
                font-size: 1.6em;
                color: #9d74a0;
                @media(max-width: 500px){
                    font-size: 10px;
                }
            }

            span {
                font-size: 2em;
                color: #b6b6b6;
                @media(max-width: 500px){
                    font-size: 10px;
                }
            }
        }
    }

    .art {
        font-size: 1.6em;
        color: #b6b6b6;
        margin-bottom: 4px;

        font-family: "FuturaPT";
        @media(max-width: 500px){
            font-size: 10px;
        }
    }

    .name {
        font-size: 1.8em;
        color: #313131;
        margin-bottom: 0.6em;
        font-family: "PoiretOne";
        font-weight: 400;
        @media(max-width: 500px){
            font-size: 12px;
        }
    }

    .price {
        text-transform: uppercase;
        font-family: "FuturaPT";
        font-weight: 500;
        @media(max-width: 500px){
            font-size: 12px;
        }

        span {
            color: #a9a9a9;
            font-size: 0.9em;
            text-decoration: line-through;

        }
    }

    .item-content {
        transition: 0.3s;
        transform: translateY(-13px);
        @media(max-width: 991px){
            transform: translateY(0);
        }
        img{
            max-width: 100%;
        }
        
    }
    .item-button {
        @media(min-width: 992px){
         transform: translateY(-13px);   
        }
        
        display: flex;
        margin: 0 auto;
        outline: none;
        background: #9d74a0;
        border-radius: 3px;
        border: none;
        padding: 8px 5.2em;
        transition: 0.3s;
        opacity: 0;
        height: auto;
        position: relative;
        z-index: 3;
        @media(max-width: 991px){
            opacity: 1;
        }
        @media(max-width: 500px){
            display: none;
        }
        span {
            color: #ffffff;
            font-size: 1.8em;
            text-align: center;
            margin: 0;
            font-weight: 200;
            font-family: "FuturaPT";
            
            .fa {
                margin-left: 8px;
            }
        }
    }
}

.offer-item{
    .favorite,
    .look {
        @media(max-width: 500px){
            width: 25px;
            .fa{
                font-size: 1.9em;
            }
        }
    }


    .stars {
        @media(max-width: 500px){
            padding: 0 2em;
        }
        .stars-wrap {
            i {
                @media(max-width: 500px){
                    font-size: 1.6em;
                }
            }

            span {
                @media(max-width: 500px){
                    font-size: 2em;
                }
            }
        }
    }

    .art {
        @media(max-width: 500px){
            font-size: 1.6em;
        }
    }

    .name {
        @media(max-width: 500px){
            font-size: 1.8em;
        }
    }

    .price {
        @media(max-width: 500px){
            font-size: 1.8em;
        }
    }

    
        .item-button {
            @media(max-width: 500px){
                display: flex;
            }
        }
    
}
.product-wr{
    position: relative;
}
.comment{
    width: 100%;
    margin-bottom: 5em;
   .comment-head{
        display: flex;
        width: 100%;
        justify-content: space-between;
        span{
            font-family: "FuturaPT";
            font-weight: 400;
        }
        .name{
            span{
                color: #9d74a0;
                font-size: 1.9em;
            }
        }
        .date{
            span, i{
                color: #cba674;
                font-size: 1.4em;
            }
        }
   } 
   .comment-body{
    background-color: #f4f4f4;
       padding: 2.5em 3em;
       p{
        color: #7e7e7e;
        font-family: "FuturaPT";
        font-size: 1.8em;
        font-weight: 300;
       }
   }
}
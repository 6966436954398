@font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTBold.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTBold.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTBold.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTBoldOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTBoldOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTBoldOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTBoldOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTBoldOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTBoldOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTBoldOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTBook.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTBook.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTBook.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTBook.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTBook.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTBook.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTBook.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTBookOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTBookOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTBookOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTBookOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTBookOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTBookOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTBookOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 300;
    font-style: normal;
  }


  @font-face {
    font-family: "FuturaPTCond";
    src: url("../fonts/Futura/FuturaPTCondBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondBold.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondBold.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondBold.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCondOblique";
    src: url("../fonts/Futura/FuturaPTCondBoldOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondBoldOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondBoldOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondBoldOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondBoldOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondBoldOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondBoldOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 8800;
    font-style: normal;
  }


  @font-face {
    font-family: "FuturaPTCond";
    src: url("../fonts/Futura/FuturaPTCondBook.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondBook.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondBook.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondBook.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondBook.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondBook.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondBook.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCondOblique";
    src: url("../fonts/Futura/FuturaPTCondBookOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondBookOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondBookOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondBookOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondBookOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondBookOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondBookOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCond";
    src: url("../fonts/Futura/FuturaPTCondExtraBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondExtraBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondExtraBold.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondExtraBold.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondExtraBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondExtraBold.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondExtraBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCondOblique";
    src: url("../fonts/Futura/FuturaPTCondExtraBoldOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondExtraBoldOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondExtraBoldOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondExtraBoldOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondExtraBoldOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondExtraBoldOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondExtraBoldOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCond";
    src: url("../fonts/Futura/FuturaPTCondMedium.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondMedium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondMedium.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondMedium.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondMedium.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondMedium.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondMedium.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTCondOblique";
    src: url("../fonts/Futura/FuturaPTCondMediumOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTCondMediumOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTCondMediumOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTCondMediumOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTCondMediumOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTCondMediumOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTCondMediumOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
  } 

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTDemi.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTDemi.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTDemi.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTDemi.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTDemi.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTDemi.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTDemi.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTDemiOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTDemiOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTDemiOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTDemiOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTDemiOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTDemiOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTDemiOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTExtraBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTExtraBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTExtraBold.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTExtraBold.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTExtraBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTExtraBold.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTExtraBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTExtraBoldOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTExtraBoldOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTExtraBoldOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTExtraBoldOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTExtraBoldOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTExtraBoldOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTExtraBoldOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTHeavy.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTHeavy.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTHeavy.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTHeavy.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTHeavy.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTHeavy.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTHeavy.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTHeavyOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTHeavyOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTHeavyOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTHeavyOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTHeavyOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTHeavyOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTHeavyOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTLight.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTLight.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTLight.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTLight.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTLight.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTLight.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTLight.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTLightOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTLightOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTLightOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTLightOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTLightOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTLightOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTLightOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 200;
    font-style: normal;
  }   

  @font-face {
    font-family: "FuturaPT";
    src: url("../fonts/Futura/FuturaPTMedium.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTMedium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTMedium.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTMedium.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTMedium.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTMedium.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTMedium.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPTOblique";
    src: url("../fonts/Futura/FuturaPTMediumOblique.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Futura/FuturaPTMediumOblique.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Futura/FuturaPTMediumOblique.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Futura/FuturaPTMediumOblique.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Futura/FuturaPTMediumOblique.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Futura/FuturaPTMediumOblique.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Futura/FuturaPTMediumOblique.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
  }

@font-face {
  font-family: "GoodVibesPro";
  src: url("../fonts/Futura/GoodVibesPro.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Futura/GoodVibesPro.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Futura/GoodVibesPro.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Futura/GoodVibesPro.svg") format("svg"), /* Legacy iOS */
    url("../fonts/Futura/GoodVibesPro.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Futura/GoodVibesPro.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Futura/GoodVibesPro.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PoiretOne";
  src: url("../fonts/Futura/PoiretOne-Regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Futura/PoiretOne-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Futura/PoiretOne-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Futura/PoiretOne-Regular.svg") format("svg"), /* Legacy iOS */
    url("../fonts/Futura/PoiretOne-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Futura/PoiretOne-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Futura/PoiretOne-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}
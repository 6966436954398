#map{
    width: 100%;
    height: 47em;
}
.contacts-page{
    font-size: calc(0.25vw + 5px);
    padding-top: 100px;
    margin-bottom: 7em;
    .link{
        p{
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 1.6em;
        }
        a{
            text-decoration: none;
            color: #9d74a0; 
        }
        span{
            color: #7e7e7e;
        }
    }
    .caption{
        margin-bottom: 10em;
        p{
            color: #2a3137;
            font-family: "FuturaPT";
            font-weight: 300;
            font-size: 4.6em;
            text-align: center;
        }
    }
    .all-contacts{
        margin-bottom: 4.5em;
        i{
            font-size: 1.8em;
            color: #9d74a0; 
        }
        span{
            font-size: 1.8em;
            color: #7e7e7e;
            font-family: "FuturaPT";
            font-weight: 300;
            @media(max-width: 770px){
                text-align: left;
                font-size: 16px;
            }
        }
        .contact-item:nth-child(2){
            text-align: center;
            @media(max-width: 770px){
                text-align: left;
            }
        }
        .contact-item:nth-child(3){
            text-align: right;
            @media(max-width: 770px){
                text-align: left;
            }
        }
    }
    .all-contacts{
        .contact-item {
            @media(max-width: 770px){
                text-align: left;
            }
        }
    }
    
    
}